import React from "react"
import { Page } from "../shared/Page/Page"
import { PageHeader } from "../shared/PageHeader/PageHeader"
import { H1, H2, P } from "../shared/Ui/Typography/Typography"
import styled from "styled-components"
import media from "css-in-js-media"
import Helmet from "react-helmet"
import { Header } from "../shared/Header/Header"
import { Footer } from "../shared/Footer/Footer"
import { Link } from "gatsby"
import { FlatButton } from "../shared/Ui/FlatButton/FlatButton"
import { Chip } from "../shared/Chip/Chip"
import { blueGrey } from "../utils/colors"

const CAREERS: {
    [category: string]: Array<{
        title: string
        url: string
        description: string
        tags: Array<Array<string>>
    }>
} = {
    // Engineering: [
    //     {
    //         title: "Flutter Mobile Developer",
    //         url: "/careers/flutter-developer",
    //         description:
    //             "Join our team and help to build an app thousands of businesses use daily to communicate with their customers and leads. A highly available and beautiful experience.",
    //         tags: [
    //             ["Full time", "info"],
    //             ["Remote", "info"],
    //             ["New", "danger"],
    //         ],
    //     },
    //     {
    //         title: "Senior Software Engineer",
    //         url: "/careers/senior-full-stack-engineer",
    //         description:
    //             "You'll be working on a diverse set of challenges to build a highly scalable platform users love globally, as a foundational team member you'll be making key decisions that help to influence the direction of the product over the next few years. We're looking for a capable and talented engineer to join us at the start of something big.",
    //         tags: [
    //             ["Full time", "info"],
    //             ["Remote", "info"],
    //         ],
    //     },
    // ],
    // Sales: [
    //     {
    //         title: "Business Development Manager",
    //         url: "/careers/business-development-manager",
    //         description:
    //             "You'll be joining a young venture backed company as the first BDM with the opportunity to build and scale the team, getting in on the ground floor of a company that's changing the way teams do business.",
    //         tags: [
    //             ["Full time", "info"],
    //             ["New", "danger"],
    //         ],
    //     },
    // ],
    // Support: [],
}

const Container = styled.div`
    background: linear-gradient(
        ${({ theme }) => theme.surfaces.lighter},
        #f8fafc
    );
    box-shadow: ${({ theme }) => theme.surfaces.dark} 0 -50rem 50rem -50px inset;

    section.hero {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 100rem 50rem;

        > * {
            max-width: 650rem;
            text-align: center;
        }

        h1 {
            font-size: 40rem;
        }

        .pre-header {
            display: flex;
            align-items: center;
            gap: 10rem;
            font-size: 14rem;
            color: ${({ theme }) => theme.text.lighter};
            font-family: "Gilroy-Regular", sans-serif;
            margin-bottom: 10rem;
        }
    }

    .roles-container {
        display: flex;
        flex-direction: column;
        gap: 100rem;
        padding-bottom: 40rem;

        .roles-card {
            background: #fff;
            box-shadow: rgba(149, 157, 165, 0.2) 0 8rem 24rem;
            border-radius: 20rem;
            display: flex;
            flex-direction: column;
            padding: 30rem 30rem;
            gap: 10rem;
        }

        .department {
            flex: 1;
            margin-bottom: 20rem;

            h3 {
                color: ${blueGrey[600]};
                font-family: "Gilroy-SemiBold", sans-serif;
                margin: 20rem 0 20rem;
            }

            .roles {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                gap: 30rem;

                > div {
                    display: flex;
                    flex-direction: column;
                    background: #fff;
                    border-radius: 8rem;
                    padding: 15rem 35rem 25rem;
                    border: 1rem solid ${blueGrey[300]};

                    a {
                        display: flex;
                        flex-direction: column;
                        flex: 1;
                    }

                    h2 {
                        font-family: Gilroy-SemiBold, sans-serif;
                        margin-bottom: 10rem;
                        font-size: 25rem;
                        color: ${blueGrey[600]};
                    }

                    .tags {
                        display: flex;
                        gap: 10rem;
                    }

                    section {
                        padding-top: 10rem;
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        gap: 30rem;
                    }

                    p {
                        font-size: 16rem;
                        line-height: 28rem;
                        font-family: "Inter", sans-serif;
                        color: ${blueGrey[500]};
                    }

                    button {
                        min-width: 150rem;
                        flex: 0;
                    }
                }

                > section {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 50rem 0 40rem;
                    background: transparent;
                    border: 2rem dashed ${blueGrey[200]};
                    color: ${blueGrey[400]};
                    border-radius: 8rem;

                    .description {
                        color: ${blueGrey[400]} !important;
                        text-align: center !important;
                        flex: 1;
                        align-items: center;
                        display: flex;
                        justify-content: center;
                        font-size: 16rem;
                        font-family: "Gilroy-Regular", sans-serif;
                    }
                }
            }

            ${media("<tablet")} {
                .roles .role {
                    padding: 5rem;
                    border: none;
                    border-radius: 0;
                    padding-bottom: 55rem;
                }

                &:not(:last-of-type) {
                    .roles .role {
                        border-bottom: 1rem solid ${blueGrey[200]};
                    }
                }

                .roles .role > a > section {
                    flex-direction: column;
                }
            }
        }

        ${media("<desktop")} {
            flex-direction: column;
        }
    }

    > div.content {
        display: flex;
        justify-content: flex-end;
        flex-direction: row-reverse;
        padding: 50rem 0 100rem;

        .text {
            max-width: 700rem;
            margin-left: 30rem;
            padding: 0 30rem;
            border-left: 1rem solid ${({ theme }) => theme.borders.normal};
        }

        .image {
            border-radius: 10rem;
            box-shadow: rgba(17, 12, 46, 0.15) 0 48rem 100rem 0;
        }

        ${media("<tablet")} {
            .text {
                border-left: none;
                padding: 0;
                margin-left: 0;
            }

            .image {
                display: none;
            }
        }
    }
`
const Industry = ({ location }) => {
    // const data = useStaticQuery(`     query {
    //           allMdx(filter: {frontmatter: {type: {eq: "career"}}}) {
    //             nodes {
    //                 slug
    //                 id
    //                 frontmatter {
    //                     title
    //                     description
    //                 }
    //             }
    //         }
    //     }`)

    return (
        <Page disableCta disableFooter disableHeader location={location}>
            <Helmet>
                <title>Careers</title>
            </Helmet>
            <Container className={"overlapPageMargins"}>
                <Header constrained />
                <PageHeader
                    className={"pageConstrained"}
                    breadCrumbs={[{ title: "Company" }, { title: "Careers" }]}
                />
                <div className={"pageConstrained"}>
                    <section className={"hero"}>
                        <div className={"pre-header"}>Careers</div>
                        <H1>
                            Join us on the ground floor of{" "}
                            <span>something big</span>
                        </H1>
                    </section>
                </div>
                <section className={"about-us"}></section>
                <div className={"roles-container pageConstrained"}>
                    <div className={"roles-card"}>
                        {Object.entries(CAREERS).map(([category, jobs]) => {
                            if (jobs.length === 0) {
                                return null
                            }

                            return (
                                <div className={"department"}>
                                    <h3>{category}</h3>
                                    <div className={"roles"}>
                                        {jobs.map((role) => (
                                            <div
                                                key={`${role.title}`}
                                                className={"role"}
                                            >
                                                <Link to={role.url}>
                                                    <H2>{role.title}</H2>
                                                    <div className={"tags"}>
                                                        {role.tags.map(
                                                            (value, index) => {
                                                                return (
                                                                    <Chip
                                                                        key={
                                                                            index
                                                                        }
                                                                        body={
                                                                            value[0]
                                                                        }
                                                                        style={{
                                                                            textTransform:
                                                                                "uppercase",
                                                                        }}
                                                                        // @ts-ignore
                                                                        variant={
                                                                            value[1]
                                                                        }
                                                                    />
                                                                )
                                                            }
                                                        )}
                                                    </div>
                                                    <section>
                                                        <P>
                                                            {role.description}
                                                        </P>
                                                        <FlatButton
                                                            variant={"flat"}
                                                            color={"primary"}
                                                            size={"larger"}
                                                            justify={"center"}
                                                            fontWeight={600}
                                                            fullWidth
                                                        >
                                                            Learn more
                                                        </FlatButton>
                                                    </section>
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {/*<div className={"content pageConstrained"}>*/}
                {/*    <div className={"text"}>*/}
                {/*        <PreSection>The brief</PreSection>*/}
                {/*        <H3>Overview</H3>*/}
                {/*        <P>*/}
                {/*            Yearning for the startup life? Want to make*/}
                {/*            meaningful decisions? Don't enjoy asking your EM for*/}
                {/*            permission to use That Library™? Let's talk!*/}
                {/*        </P>*/}
                {/*        <H3>About us</H3>*/}
                {/*        <P>*/}
                {/*            We're a young company with customers and capital,*/}
                {/*            starting out in our journey to shake up an ancient*/}
                {/*            industry and take over the world.*/}
                {/*        </P>*/}
                {/*        <P>*/}
                {/*            At our core we believe in a modern approach to the*/}
                {/*            workplace with part time work from home included and*/}
                {/*            stock options. <sup>1</sup>*/}
                {/*        </P>*/}
                {/*        <P>*/}
                {/*            If you work in sales we provide a generous base*/}
                {/*            salary and commission scheme, and if you're a tech*/}
                {/*            wizard you should know we run on...*/}
                {/*            <ol>*/}
                {/*                <li>ReactJS</li>*/}
                {/*                <li>Python Flask</li>*/}
                {/*                <li>Google Cloud Platform</li>*/}
                {/*                <li>Coffee</li>*/}
                {/*                <li>Beer when the code doesn't work</li>*/}
                {/*            </ol>*/}
                {/*        </P>*/}
                {/*        <H3>Open roles</H3>*/}
                {/*        <P>*/}
                {/*            We're currently looking for applicants in a variety*/}
                {/*            of roles, including...*/}
                {/*            <ol>*/}
                {/*                <li>Sales manager</li>*/}
                {/*                <li>Industry sales person</li>*/}
                {/*                <li>Senior Software Engineer</li>*/}
                {/*                <li>"Pick up the beer" guy</li>*/}
                {/*            </ol>*/}
                {/*        </P>*/}
                {/*        <H3>Next steps</H3>*/}
                {/*        <P>*/}
                {/*            If you're interested in hopping on this elevator*/}
                {/*            then contact our team at{" "}*/}
                {/*            <a*/}
                {/*                href={*/}
                {/*                    "mailto:hello@23shout.com?subject=Careers"*/}
                {/*                }*/}
                {/*            >*/}
                {/*                <TextButton color={"primary"}>*/}
                {/*                    hello@23shout.com*/}
                {/*                </TextButton>*/}
                {/*            </a>{" "}*/}
                {/*            or via our{" "}*/}
                {/*            <Link to={`/support/contact`} tabIndex={-1}>*/}
                {/*                <TextButton color={"primary"}>*/}
                {/*                    contact form*/}
                {/*                </TextButton>*/}
                {/*            </Link>*/}
                {/*            .*/}
                {/*        </P>*/}
                {/*        <P style={{ fontSize: "12rem" }}>*/}
                {/*            <sup>1</sup> You may be challenged to a Halo game at*/}
                {/*            some point. Participation optional.*/}
                {/*        </P>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </Container>
            <Footer constrained />
        </Page>
    )
}

export default Industry
